/* Hobbies */

.hobbies {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.hobbies figure {
  text-align: center;
}

.hobbies img {
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  /* height: 650px;
  width: 500px; */
}

.hobbies li {
  margin: 5px auto;
}

@media only screen and (max-width: 768px) {
  .hobbies {
    grid-template-columns: 1fr;
  }
  /* .hobbies img {
    height: 400px;
    width: 300px;
  } */
}

@media only screen and (max-width: 375px) {
  /* .hobbies img {
    height: 300px;
    width: 200px;
  } */
}
