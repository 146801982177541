.skillContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3%;
}

.skillHeader {
  text-decoration: underline;
}

.techSkills {
  grid-row: span 2;
}

@media only screen and (max-width: 735px) {
  .skillContainer {
    grid-template-columns: 1fr;
    margin-bottom: 5%;
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 375px) {
}
