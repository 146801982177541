/* Footer */

#footer {
  background-color: var(--navColor);
  border-top: 1px solid var(--backgroundColorAboutBorder);
  /* box-shadow: 20px 20px 5px 25px rgb(104, 104, 104); */
  /* font-family: "Parisienne", cursive; */
  height: auto;
  padding: 8px;
  color: white;
  margin-top: auto;
}

#footer a {
  color: white;
  text-decoration: none;
}

#footer > div {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.link {
  text-decoration: underline;
}

.link:hover {
  color: lightblue;
}

@media only screen and (max-width: 768px) {
  #footer > div {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 375px) {
  #footer > div {
    grid-template-columns: 1fr;
  }
}
