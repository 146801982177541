/* Photo */

.headshot {
  text-align: center;
}

.image.show {
  margin-top: 5%;
  transition: height ease 0.3s;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 3px solid black;
  height: 588px;
  /* width: 300px; */
}

.image.hide {
  transition: height ease 0.3s;
  height: 0;
}

.headshot button {
  margin: 10px;
  background-color: var(--buttonColor);
  color: var(--textColorPrimary);
  border-radius: 8px;
  padding: 6px;
}

@media only screen and (max-width: 1440px) {
  .image.show {
    height: 442px;
  }
}

@media only screen and (max-width: 1024px) {
  .image.show {
    height: 311px;
  }
}

@media only screen and (max-width: 886px) {
  .image.show {
    height: 800px;
  }
}

@media only screen and (max-width: 756px) {
  .image.show {
    height: 600px;
  }
}

@media only screen and (max-width: 425px) {
  .image.show {
    height: 428px;
  }
}

@media only screen and (max-width: 375px) {
  .image.show {
    height: 398px;
  }
}

@media only screen and (max-width: 320px) {
  .image.show {
    height: 335px;
  }
}
